import { QueryClientConfig } from "@tanstack/react-query";

export const defaultTanstackQueryConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      // With SSR, we usually want to set some default staleTime
      // above 0 to avoid refetching immediately on the client
      staleTime: 10 * 60 * 1000,
    },
  },
};
