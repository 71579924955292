import { GOD_VIEW_STORAGE_KEY, USER_SETTINGS_STORAGE_KEY } from "#constants/common";
import { AdminOrganisation } from "#customTypes/admin";
import { RootState } from "../store";
import { UserSettings } from "#features/userSettings/userSettingsSlice";

export const getInitialClientState = (state: RootState): RootState => {
  if (typeof window === "undefined") {
    return state;
  }

  const parsedGodView = parseStorage<AdminOrganisation>(
    GOD_VIEW_STORAGE_KEY,
    ["id", "name", "logo"],
    "sessionStorage"
  );

  if (parsedGodView) {
    state.godView.selectedOrganisation = parsedGodView;
  }

  const parsedUserSettings = parseStorage<UserSettings>(USER_SETTINGS_STORAGE_KEY, [
    "channelBriefsAdminView",
  ]);

  if (parsedUserSettings) {
    state.userSettings = { ...state.userSettings, ...parsedUserSettings };
  }

  return state;
};

const parseStorage = <T extends object>(
  key: string,
  objectKeys: (keyof T)[] = [],
  type: "localStorage" | "sessionStorage" = "localStorage"
): T | undefined => {
  const item =
    type === "localStorage" ? localStorage.getItem(key) : sessionStorage.getItem(key);

  const parsedItem = JSON.parse(item || "{}");

  const filteredObject = Object.keys(parsedItem).reduce((acc, key) => {
    if (objectKeys.includes(key as keyof T)) {
      acc[key as keyof T] = parsedItem[key];
    }

    return acc;
  }, {} as T);

  if (Object.keys(filteredObject).length) {
    return filteredObject;
  }

  return undefined;
};
