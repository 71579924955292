import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
const initialState = "";
const selectedBriefRefSlice = createSlice({
  name: "selectedBriefRef",
  initialState,
  reducers: {
    setSelectedBriefRef(state, action: PayloadAction<string>) {
      state = action.payload;
      return state;
    },
  },
});
export default selectedBriefRefSlice.reducer;
export const { setSelectedBriefRef } = selectedBriefRefSlice.actions;
export const selectedBriefRef = (state: RootState) => state.selectedBriefRef;
