import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Organisation } from "../../types/db";

const initialState: { organisation: Organisation | undefined } = {
  organisation: undefined,
};

/**
 * @deprecated Do not use this slice directly, use the useWlaOrganisation hook instead
 * TODO: Remove this when we migrate channels & my-organisation-positions api calls to Axios
 * Do not access this state directly, use the useWlaOrganisation hook instead
 */
const wlaOrganisationSlice = createSlice({
  name: "wlaOrganisation",
  initialState,
  reducers: {
    setWlaOrganisation(state, action: PayloadAction<Organisation>) {
      state.organisation = action.payload;
      return state;
    },
  },
});

export default wlaOrganisationSlice.reducer;
/**
 * @deprecated Do not use this slice directly, use the useWlaOrganisation hook instead
 */
export const { setWlaOrganisation } = wlaOrganisationSlice.actions;
