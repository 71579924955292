import { MOBILE_APP_STORAGE_KEY, PLATFORM_STORAGE_KEY } from "#constants/common";

const ALLOWED_PLATFORMS = ["ios", "android"];

export const initializeSessionStorage = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const isAppQueryParam = searchParams.get("app") === "1";
  const platform = searchParams.get("platform");

  if (isAppQueryParam) {
    sessionStorage.setItem(MOBILE_APP_STORAGE_KEY, "true");

    if (platform && ALLOWED_PLATFORMS.includes(platform)) {
      sessionStorage.setItem(PLATFORM_STORAGE_KEY, platform);
    }
  } else {
    sessionStorage.removeItem(MOBILE_APP_STORAGE_KEY);
    sessionStorage.removeItem(PLATFORM_STORAGE_KEY);
  }
};
